import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';

firebase.initializeApp({
    apiKey: 'AIzaSyBXPywPbv5aMHya85MvWGLZLyau8PkrJ94',
    authDomain: 'avalon-16935.firebaseapp.com',
    databaseURL: 'https://avalon-16935-default-rtdb.firebaseio.com',
    projectId: 'avalon-16935',
    storageBucket: 'avalon-16935.appspot.com',
    messagingSenderId: '263516355840',
    appId: '1:263516355840:web:0b072d65a669b40c3f87e5',
    measurementId: 'G-KCNJ5R7GV4',
});

firebase.analytics();

firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return firebase.auth().signInAnonymously();
    })
    .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;

        // eslint-disable-next-line no-console
        console.log(`${errorCode} ${errorMessage}`);
    });

export default firebase;
export const db = firebase.database();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
