/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { GameStateType, PlayerType } from '../types';
import { Box, Flex, Text, Grid, useColorMode } from 'theme-ui';
import { ReactComponent as SunIcon } from '../img/brightness.svg';




export const Header = () => {
    const [colorMode, setColorMode] = useColorMode();

    return (
     <>
     <div className="header-container">
         <div>Avalon</div>
         <div>
         {/* <Box>
                <Flex
                    sx={{
                        mt: 2,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <Box sx={{ cursor: 'pointer' }}>
                        <SunIcon
                            fill={colorMode === 'default' ? 'black' : 'white'}
                            className={colorMode === 'default' ? 'blacksun' : 'white'}
                            onClick={(e) => {
                                setColorMode(
                                    colorMode === 'default'
                                        ? 'dark'
                                        : 'default',
                                );
                            }}
                            width="20px"
                            height="20px"
                        />
                    </Box>
                </Flex>
            </Box> */}
         </div>
     </div>
     </>
    )
};

